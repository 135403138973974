.a{fill:#000;}
/* .navbar-light .navbar-nav .nav-link svg{
  transform: rotate(0deg);
} */
/* .sub-menu {
          display: none;
          position: absolute;
          top: 100%;
          left: 0;
          background-color: #fff;
          border: 1px solid #ccc;
          padding: 10px;
        }
        
        .nav-item:hover .sub-menu {
          display: block;
        } */

        /* .navbar-toggler-icon{
          margin-left:100px;
          margin-top :35px;
          display:block;
        } */
/* 
        .navbar-toggler-icon{
          display: block;
          margin: 7px 0;
        } */