@media all and (max-width: 767px) {
	.agln-logo {
        width: 34%;
    }
    .companies-group {
	    padding: 40px 0 10px;
	}
	.second-ul {
		padding-top: 30px;
	}
	.navbar .logo {
        max-width: 90% !important;
        margin-left: 5px !important;
    }
    .right-nav .signin-link {
        font-size: 16px;
    }
    .banner-sec {
        padding: 30px 5px 0px 5px;
    }
    .banner-txt {
        margin-bottom: 5px;
    }
    .offering-list li .offer-icn {
        margin-right: 8px;
    }
    .sales-exe {
        padding-top: 20px;
        justify-content: center !important;
    }
    .tiva-whtsapp:after {
        width: 78%;
        left: 20px;
     }
    .exe-prof .txt {
        font-size: 13px;
        line-height: 18px;
    }
     .sales-exe .exe-prof {
        width: 220px;
    }
    .first-cust-col img {
        width: 18%;
        margin-right: 11px;
    }
    .third-cust-col img {
        width: 13%;
        margin-right: 11px;
    }
    .sec-cust-col img {
        width: 42%;
        margin-right: 11px;
    }
    .companies-growth .growth-title {
        font-size: 18px;
    }
    /*.first-cust-col .d-flex, .sec-cust-col .d-flex, .companies-growth .d-flex {
        justify-content: center;
    }*/
    .first-cust-col, .sec-cust-col, .companies-growth {
        margin-bottom: 30px;
    }
    .companies-growth .desc {
        text-align: left;
    }
    .advisor-sec .service-title {
        font-size: 24px;
        margin-bottom: 0;
    }
    .advisor-sec .btn-primary {
        margin-bottom: 0px;
    }
    .news-cust-advs .white-box {
        margin-top: 20px;
        margin-bottom: 50px;
    }
    .advisor-card h4 {
        min-height: auto;
    }
    .cust-ad-para {
        height: 181px;
    }
    .our-services .service-title {
        font-size: 30px;
     }
     .other-services-sec .service-title span {
       margin-top: 3px;
    }
    .other-services-sec .service-title {
        padding: 8px 15px;
    }
    .service-list li a {
        padding: 8px 15px;
    }
     .service-list {
        padding-bottom: 13px;
    }
    .companies-growth .desc {
        text-align: left;
    }
    .news-details.cust-ad-para {
	    height: 181px;
	}
	.other-news a {
        display: block;
    }
    .other-news .view-img figure {
        height: 64px;
        width: 52%;
        margin-right: 10px;
    }
    .consolidation-details p {
       text-align: justify;
    }
    .booking-card {
        margin: 0px 20px 20px 30px;
    }
    .excellence-list li span img {
        width: 85%;
    }
     .excellence-list li strong {
        font-size: 20px;
        line-height: 27px;
    }
     .contact-info-wrp .corporate-office .address.border-bottom {
        border-bottom: none !important;
        margin-bottom: 0px;
        padding-top: 20px;
        padding-bottom: 30px !important;
    }
    .most-service .list-item strong {
	    padding-bottom: 0px;
	}

    /*.companies-growth .desc {
        margin-bottom: 25px;
    }*/
    .companies-growth .cust-offset {
        padding-left: 0%;
        text-align: center;
    }
    .cust-fig {
	    top: 16px !important;
	}
	.other-services-sec .in-news-details {
    	order: 1;
    }
    .other-services-sec .left-nav {
    	order: 2;
    }
    .other-services-sec {
	    margin-top: 0px;
	}
	.in-news-details table {
		display: block;
	    width: 100%;
	    overflow-x: auto;
	}
	.navbar-nav .nav-link {
	    padding: 10px 10px 10px 20px;
	}
	.page-tabs-sec p {
	    text-align: justify;
	}
	.most-service .list-item {
		display: flex;
       align-items: center;
	}
	.most-service .list-item strong a {
		position: relative !important;
        padding-left: 0 !important;
	}
	/*.country-list {
		display: flex;
	     overflow-y: scroll; 
	    white-space: nowrap;
	}
	.country-sub-menu {
	    position: initial;
	}*/
    

    
    .companies-group li {
        margin-bottom: 20px;
    }
    .companies-group img {
        width: 70%;
    }
    .cargo-consolidation .btn-wrap {
        margin-top: 16px;
    }
    .global-services-sec .service-block span {
        width: 50px;
        padding: 13px;
    }
    .offering-list {
        display: flex;
        flex-wrap: wrap;
    }
    .offering-list li {
        width: 50%;
        margin-top: 20px;
    }
    .right-nav {
        right: 75px;
        top: 23px;
    }
}
@media all and (min-width: 768px) {
    .cust-col-width {
        flex: 0 0 34.333333%;
        max-width: 34.333333%;
    }

	
.contact-info-wrp .corporate-office .address {
    position: relative;
    padding: 15px 15px 1px 38px;
    margin: 0px 0px 20px 0px;
    border-top: 1px solid #eaeaea;
    width: 50%;
    float: left;
}
	
	
	.cargo-consolidation{padding-bottom:30px}
	.nav-brand {
		width: 160px;
	}
	.right-nav {
		right: 65px;
	}
	.right-nav img {
		width: 25px;
		height: 25px;
	}
	.right-nav .dropdown > a {
		margin-left: 15px;
	}
	.banner-sec {
	    /*padding: 98px 0 98px;*/
	    position: relative;
	    min-height: 540px;
	    padding: 0;
	    display: flex;
	    align-items: center;
	}
	.main-title {
		font-size: 47px;
		margin-bottom: 0px;
	}
	.digital-txt {
		font-size: 30px;
	}
	.banner-txt {
		font-size: 18px;
		margin-bottom: 40px;
	}
	.banner-txt a {
		font-size: 16px;
	}
	.offering-list {
		display: flex;
	}
	.sales-exe {
		position: absolute;
		bottom: 0;
		/*right: 0;*/
		right: 100px;
	}
	.companies-group .wrap {
		display: flex;
		justify-content:space-between;
		padding-left: 30px;
	}
	/*.companies-group .info{*/
	/*	width: 48%;*/
	/*}*/
	.service-card {
		margin-bottom: 30px;
	}
	.booking-card p { 
    min-height: 90px;
	}
	.companies-group .wrap .info:first-child {
		margin-bottom: 0;
	}
	/*.companies-growth .col-md-4 {*/
	/*	position: relative;*/
	/*	flex: 0 0 30.333333%;*/
 /*       max-width: 30.333333%;*/
	/*}*/
	/*.companies-growth .col-md-4:first-child:before,*/
	/*.companies-growth .col-md-4:after {*/
	/*	content:"";*/
	/*	position: absolute;*/
	/*	right: 56px;*/
	/*	top: -56px;*/
	/*	width: 1px;*/
	/*	opacity: 0.2;*/
	/*	height: 193px;*/
	/*	background: #fff;*/
	/*	transform: rotate(25deg);*/
		
	/*}*/
	/*.companies-growth .col-md-4:before*/
	/* {*/
	/*	content:"";*/
	/*	position: absolute;*/
	/*	left: -18px;*/
	/*	top: -70px;*/
	/*	width: 1px;*/
	/*	opacity: 0.2;*/
	/*	height: 193px;*/
	/*	background: #fff;*/
	/*	transform: rotate(35deg);*/
		
	/*}*/
	/*.companies-growth .col-md-4:last-child:after{*/
	/*	content:"";*/
	/*	position: absolute;*/
	/*	right: 15px;*/
	/*	top: -70px;*/
	/*	width: 1px;*/
	/*	opacity: 0.2;*/
	/*	height: 193px;*/
	/*	background: #fff;*/
	/*	transform: rotate(35deg);*/
	/*}*/
	.advisor-card {
		height: 100%;
		position: relative;
		padding-bottom: 50px;
	}
	.advisor-card a {
		/*position: absolute;*/
		left: 0;
		bottom: 30px;
	    display: block;
        text-decoration: none;
	}
	.footer-nav li {
	    padding: 8px 0;
	}
	.social-list li a {
		width: 25px;
		height: 25px;
	}
	.offering-list li {
		position: relative;
		padding-right: 65px;
	}
	.offering-list li:after {
		content:"";
		position: absolute;
		right: 28px;
		top: 50%;
		transform:translateY(-50%);
		width: 1px;
		height: 50px;
		background: #EDEDED;
		opacity: 0.3;
	}
	.offering-list li:last-child:after {
		display: none;
	}
	
	.other-news .view-img figure {
        /*width: 80px;*/
        width: 190px;
        height: 60px;
    }
.other-news .post-title {
    width: calc(100% - 69px);
    padding-left: 15px;
}
.news-search .form-group:last-child {
    margin-left: 15px;
}
/*about start*/
	.brochure-dwn .dwn-desc {
		width: calc(100% - 368px);
		padding: 0 20px;
	}
	.excellence-list li {
		width: 50%;
		
	}
	.page-tabs-sec .nav-tabs {
		text-align: center;
	}
	.team-tab-content h2 {
	    padding: 0 12%;
	}
	.business-card {
		margin-bottom: 30px;
	}
	.customer-login {
		max-width: 600px;
		margin: 0 auto;
	}
	.customer-login .booking-card {
		width: 45%;
	}
	.customer-login .booking-card p {
	    min-height: 50px;
	}
	.customer-title {
		margin-bottom: 80px;
		font-size: 28px;
	}
	.customer-login-banner {
		min-height: 400px;
		padding: 0;
	}
	
	.register-sec h2 {
    font-size: 16px; 
    text-align: center;
}
/*login*/
	.customer-login {
		max-width: 900px;
		margin: 0 auto;
	}
	.customer-login .booking-card {
		width: 45%;
	}
	.customer-login .booking-card p {
	    min-height: 50px;
	}
	.customer-title {
		margin-bottom: 50px;
		font-size: 28px;
	}
	.customer-login-banner {
		min-height: 400px;
		padding: 0;
	}
	.tg-login-popup {
	    width: 320px;
	    padding: 20px;
	}
	.tg-login-popup a {
		font-size: 16px;
	}
	.tg-login-popup .nav-desc {
	    font-size: 13px;
	}
	.tg-login-popup .nav-title  {
		margin-bottom: 5px;
	}
	.tg-login-popup ul a {
	    padding: 8px 20px 8px 8px;
	    margin: 0;
	}
	.tg-login-popup h6 {
		font-size: 20px;
	}	
	.country-list {
		text-align: center;
		padding: 10px 10px 0 10px;
		border-top: 1px solid #efefef; 
		border-bottom: 1px solid #efefef;
		margin-bottom: 30px;
	}
	.country-list > li {
		margin: 0;
		display: inline-block;
		padding: 0 27px;
	}
	
}

@media all and (min-width: 1440px) {
    .banner-sec {
        min-height: 600px;
    }
}
@media all and (min-width: 768px) and (max-width: 991px) {
	

	.booking-card p {
    font-size: 12px ;}
	
	.booking-link .Check-btn { 
    font-size: 12px;
    padding: 6px 10px;
}
.booking-link .registere-link { 
    font-size: 12px;
    margin-left: 6px;
}
.booking-link .Check-btn { 
    font-size: 12px;
    padding: 6px 10px;
}
	
	.booking-card { 
    padding: 15px 15px 15px 23px; 
}
}

@media all and (min-width: 992px) {
	.booking-card p {
	    min-height: 80px;
	}
	.our-services {
	    padding: 60px 0 40px;
	}
	.advisor-sec {
	    padding: 50px 0;
	    /*padding-bottom: 0;*/
   }
   .pagination-sec { 
    padding: 30px 0;
}
.register-sec h2 {
    font-size: 20px;
    text-align: center;
}
.register-sec .register-details h6 { 
    font-size: 15px; 
}
.register-sec .register-details .form-control {
    font-size: 14px;
}
.register-sec .register-details h6 { 
    margin-bottom: 15px; 
}

.register-details .form-row {margin-bottom:20px;}
.register-sec {
    padding: 60px 0;
}
.register-sec .check-services { 
    margin-bottom: 30px;
}



}

@media all and (min-width: 1200px) {
	.tw-logo {
        width: 72% !important;
    }
	.blue-strip .first-cust-col {
        margin-right: 100px;
    }
	.agln-logo {
        width: 15%;
    }
    .second-ul {
        margin-left: 0;
    }
    .first-ul {
        margin-left: 0;
        padding-left: 0;
        width: 80%;
    }
    .blue-strip .left-img {
        /*width: 80px;*/
        /*height: 60px;*/
        margin-right: 15px;
    }
    .first-cust-col {
        flex: 0 0 31.333333%;
        max-width: 31.333333%;
    }
    .first-cust-col img {
        width: 24%;
    }
    .sec-cust-col {
        /*margin-left: -40px;*/
        flex: 0 0 40.333333%;
        max-width: 40.333333%;
    }
    .sec-cust-col img {
        width: 41%;
        margin-right: 25px;
        height: 60px;
    }
    .third-cust-col {
        flex: 0 0 28.333333%;
        max-width: 28.333333%;
        max-width: 28.333333%;
    padding-left: 70px;
    }
    .third-cust-col img {
        width: 16%;
    }
    .navbar-nav {
        align-items: center;
    }
    .contact-info-wrp .corporate-office {
        padding: 40px 25px;
    }
    .corporate-team .col-lg-3, .corporate-team .col-lg-4 {
        padding-right: 7px !important;
        padding-left: 7px !important;
    }
    /*.corporate-team .col-lg-4 {*/
    /*    -ms-flex: 0 0 29.333333%;*/
    /*    flex: 0 0 29.333333%;*/
    /*    max-width: 29.333333%;*/
    /*}*/
    .news-cust-advs .white-box .advisor-card h4 {
        font-size: 18px;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
         min-height: auto;
    }
    .news-cust-advs .white-box .advisor-card .cust-ad-para {
        height: 133px;
    }
    .website-policy p { 
        font-size: 15px;
        line-height: 23px;
    }
 /*   .companies-growth .cust-offset .col-md-4 {*/
 /*       padding-left: 5%;*/
 /*       padding-right: 5%;*/
 /*   }*/
	/*.companies-growth .cust-offset .col-md-4:nth-child(2) {*/
	/*    padding-left: 4%;*/
 /*       padding-right: 4%;*/
	/*}*/
	.last-col {
	    padding-left: 70px;
	}
	.sec-last-col {
	    padding-left: 29px;
	}
	.second-col {
	    padding-left: 22px;
	}
	.digital-offering-col {
	    margin-left: -20px;
        margin-right: 20px;
	}
	.contact-info-wrp .corporate-office .address p {
		font-size: 15px; 
		padding-left: 8px;
	}
	 
	 
	.contact-info-wrp .corporate-office .corporate-title h4 {
		font-size: 20px; 
	}
	 
	 
	.navbar {
		
		padding: 0px 55px 0px 40px;
	}
	.nav-brand {
		margin: 10px 0;
	}
	.right-nav{
		padding-top: 0px;
	}
	.navbar-collapse {
		padding: 0;
		padding-top: 8px;

	}
	.right-nav {
		position: static
	}
	.sales-exe .exe-prof {
		margin-right: 0;
	}
	.navbar {
			/* position: fixed;
			width: 100%;
			height: 4.2em;
			top: 0;
			background-color: #fff; 
			z-index: 1000;  */
		padding: 0px 55px 0px 40px;
		justify-content:space-between;
		
	}
	.navbar-collapse {
		flex-grow:inherit;
	}
	.nav-brand {
		width:auto;
	}
	.navbar-nav .nav-link {
		padding: 15px 13px !important;
	}
	.sales-exe .exe-prof {
		/*width: 420px;*/
		width: 360px;
	}
	.exe-prof .info {
		/*width: 235px;*/
		width: 100%;
	}
	.exe-prof .txt {
        font-size: 12px;
    }
	.offering-list {
		width: 80%;
	}
	.offering-list li .offer-icn{
		margin-right: 10px;
	 /*   width: 98px;*/
		/*height: 80px;*/
		width: 60px;
		height: 60px;
	}
	.offering-list li:first-child .offer-icn {
	    width: 86px;
	    height: 74px;
	}
	.companies-group {
		position: relative;
	}
	.first-ul img {
	    margin-left: -15px;
	}
	.second-ul img {
	    margin-right: -15px !important;
	}
	/*.companies-group:after {*/
	/*	width: 1px;*/
	/*	height: 45px;*/
	/*	content:"";*/
	/*	position: absolute;*/
	/*	left: 47%;*/
	/*	top: 52%;*/
	/*	transform: translate(-50%,-50%);*/
	/*	background: #eaeaea;*/
	/*}*/
	.companies-group strong {
		/*width: 35%;*/
		margin: 0;
		padding-right: 20px;
	
	}
	.companies-group strong.members-of {
	    padding-right: 0px !important;
	    /*padding-left: 33px;*/
	    padding-left: 0px;
	}
	.second-ul li { margin-left: -2px; }
	.companies-group span {
		/*width: 65%;*/
		margin: 0;
	}
	/*.cust-ml {*/
	/*    margin-left: 30px;*/
	/*}*/
	/*.cust-mr {*/
	/*    margin-right: 30px;*/
	/*}*/
	/*.companies-group .cust-pl {
	    padding-left: 30px;
	    margin-left: 40px;
	}*/
	.companies-group img {
	    width: 76%;
        margin-right: 6px;
	}
	.companies-group img.fiata-logo { width: 55%; }
    .members-logo {
        width: 72% !important;
    }
	.secondry-footer {
		padding: 23px 0;
	}
	.secondry-footer p {
		margin: 0;
	}
	.navbar-light .navbar-nav .nav-link {
		font-size: 14px;
		font-weight: 500;
	}
	.right-nav .dropdown > a {
		font-size: 15px;
	}
	.right-nav a img{
		margin-right: 4px;
	}
	.digital-txt {
	    font-size: 39px;
        margin-bottom: 120px;
	}
	
	.offering-list li:after {
		height: 70px;
	}
	/***  services responsiv  start ***/
	
		.consolidation-details h4 {
    font-size: 22px;
	}
	.consolidation-details p { 
    font-size: 16px;
    text-align: justify;
}

.other-services-sec .service-title {
    padding: 15px 15px;
}

.other-services-sec .service-title {
	    font-size: 16px;
}
.service-title span {
    width: 22px; 
}
	
.service-list li a {  
    font-size: 14px; 
	padding: 15px 15px;
}
.cargo-consolidation h2 { 
    font-size: 30px;
}
.breadcrumb-wrap .breadcrumb { 
    font-size: 14px;
}
.cargo-consolidation { 
    padding: 30px 0;
	
}


.most-service .list-item strong { 
    font-size: 16px; 
    line-height: 25px;
}
.most-service .list-item p { 
    font-size: 14px;
}
.most-service img {
    width: 50px;
}

.most-service .list-item { 
    padding-left: 70px;
    display: flex;
    align-items: center;
}
.cargo-consolidation .btn-wrap .btn-primary {
    font-size: 15px;
    padding: 10px 15px;
     
}


.ecommerce-section h2 {
    font-size: 40px; 
}

.ecommerce-section h4 {
    font-size: 25px; 	
}
.ecommerce-section p {
    font-size: 18px;
    line-height: 1.5;
}

.digital-title { 
    font-size: 25px; 
}
.booking-card h4 { 
    font-size: 20px; 
}

.booking-card p {
    font-size: 16px; 
}

/***  services responsiv  end ***/

/***  news landing responsiv  start ***/
.news-search .form-group:last-child{margin-left: 15px;}

.news-sec p span, .recent-news-sec p span {
    font-size: 16px; 
}

.news-sec h2, .recent-news-sec h2 { 
    font-size: 26px;
}

.news-sec p, .recent-news-sec p, .advisor-card p {
    font-size: 18px ; 
        text-align: justify;
}
.news-sec .more-link { 
    font-size: 16px; 
}

.recent-news-sec h6 { 
    font-size: 24px;
}

.recent-news-sec .more-link { 
    font-size: 16px; 
}
.news-search .search-btn { 
    right: 13px; 
}
.news-sec {
    margin: 50px 0px;
}

.post-row { 
    padding: 35px 0px 0;
}
.recent-news-sec figure {
    margin-bottom: 25px;
}

.pagination-sec { 
    padding: 0px 0 0 0;
}
/***  news landing responsiv  end ***/
/*about page start*/
.about-tab-content {
	padding-bottom: 40px;
}
.cargo-consolidation.about-txt {
    margin-bottom: 40px;
}
.page-tabs-sec .nav-tabs .nav-link, .page-tabs-sec .nav-tabs .nav-link.active, .page-tabs-sec .nav-tabs .nav-item.show .nav-link {
    font-size: 17px;
}
.page-tabs-sec .nav-tabs {
    margin-bottom: 35px;
}	
.page-tabs-sec h6 {
	font-size: 20px;
}
.page-tabs-sec h4 {
	font-size: 25px;
}
.page-tabs-sec p {
	font-size: 16px;
    margin-bottom: 20px;
    text-align: justify;
}

.page-tabs-sec ul li {
    font-size: 16px;
}
.accr-list li span {
    width: 128px;
    height: 128px;
}
.brochure-dwn {
    padding: 25px 0;
    margin-bottom: 30px;
}
.brochure-dwn .dwn-desc {
    width: calc(100% - 400px);
    padding: 0 30px;
}
.brochure-dwn .dwn-desc {
	margin: 0;
	font-size: 15px;
}
.excellence-list li {
	width: 27%;
	position: relative;
	border-left: 1px solid #E5E5E5;
}
.excellence-list li:nth-child(3) {
	width: 19%;
}
.excellence-list {
    margin-bottom: 35px;
}
.business-card {
	padding: 26px 20px 0 20px;
}
.business-card figure {
    width: 140px;
    margin: 0 auto 25px;
}
.business-card strong {
	font-size: 24px;
}
.business-card span {
	font-size: 15px;
	min-height: 30px;
}
.business-card p {
	font-size: 17px;
}
.team-tab-content .team-wrap {
    padding: 35px 5%;
}
.team-tab-content h2, .about-tab-content h2 {
    font-size: 32px;
    margin-bottom: 50px;
}
.other-news .post-title h6 { 
    font-size: 14px; 
}
.in-news-sec span {
    font-size: 14px; 
}
.in-news-sec h2 { 
    font-size: 22px;
}
.in-title { 
    font-size: 14px; 
}
.in-news-sec h3 {
    font-size: 22px; 
}
.in-news-sec p {
    font-size: 15px; 
    text-align: justify;
}
.comment-box .btn { 
    font-size: 16px; 
}
.more-comments { 
    font-size: 14px; 
}
.share-ion {
    color: #0E3855;
    font-size: 14px;
}
.feedback-cmm h4 { 
    font-size: 16px; 
}
.comment-box .form-control {
    font-size: 15px;
}
.in-news-details h4 {
	font-size: 24px;
}
.login-dropdown {
	right: 20px;
}
.login-dropdown a {
	font-size: 16px;
	margin: 0;
}
.login-dropdown a i {
	right: 0;
}
.customer-title {
    font-size: 34px;
}



.register-sec .back-arrow { 
    font-size: 15px; 
}
.register-sec h2 {
    font-size: 24px; 
}
.register-sec .register-details { 
    padding: 35px; 
}

.register-sec .register-details h6 {
    margin-bottom: 25px;
}
.register-sec .register-details h6 {
    font-size: 18px;
}
.register-sec .register-details .form-control {
    font-size: 16px;
}
.register-sec .register-details .form-check-label { 
    font-size: 16px; 
}
.register-sec .register-details p {
    font-size: 15px; 
}
.register-sec .submit-details-btn {
    padding: 10px 40px;
    font-size: 14px;
}
.global-services-sec .service-block .service-title h6 { 
    font-size: 17px;
}
.global-services-sec .service-block a {
    font-size: 14px; 
}
.global-services-sec .service-block .service-title {
    width: calc(100% - 60px);
        padding-left: 10px;
}
.global-services-sec .service-block span {
    width: 55px;
}

.text-capitalize .global-icn img {
    width: 50px;
}
/*login*/
.login-dropdown {
	right: 20px;
}
.login-dropdown a {
	font-size: 16px;
	margin: 0;
}
.login-dropdown a i {
	right: 0;
}
.customer-title {
    font-size: 34px;
}
.login-dropdown.tg-login-popup a i {
	right: 10px;
}
.navbar-nav .nav-item {
	position: relative;
}
.navbar-nav .nav-item:hover .sub-menu {
	display: block;
}
.sub-menu {
	box-shadow: 0px 3px 6px #00000029;
	background: #ffffff;
	border-radius: 6px;
	position: absolute;
	left: 0;
	top: 100%;
	width: auto;
	z-index: 2;
	padding: 7px 0;
}
.sub-menu:after {
	position: absolute;
	left: 15%;
	top: -10px;
	transform:translateX(-50%);
	width: 0; 
  	height: 0; 
  	border-left: 7px solid transparent;
  	border-right: 7px solid transparent;
  	border-bottom: 10px solid #ffffff;
  	content:"";
}
.sub-menu  li {
	padding: 0;
}
.sub-menu a {
	padding: 7px 24px;
	font-size: 14px;
}
.contact-head .cargo-consolidation {
	padding: 70px 0;
}
}

@media all and (min-width: 1599px) {
	.second-ul img {
	    margin-right: 15px !important;
	}
    .tiva-whtsapp {
        font-size: 24px;
        padding-bottom: 15px;
      }
      .tiva-whtsapp:after {
        position: absolute;
        width: 40%;
        left: 166px;
        
    }
    .contact-info-wrp .corporate-office  .corporate-team .teamglobal-card a{
    	font-size:14px;
    	
    }
    .thank-you h1 {
        font-size: 36px;
        line-height: 44px;
    }
    .thank-you p {
        font-size: 20px;
    }
    .thank-you .check-icon {
        width: 55px;
    }
    .thank-you a {
        font-size: 18px;
    }
    .first-cust-col {
        flex: 0 0 29.333333%;
        max-width: 31.333333%;
    }
    
    .sec-cust-col {
         margin-left: -30px; 
    }
    .blue-strip .left-img {
        width: 90px;
        height: 70px;
    }
    .sec-cust-col img {
        height: 70px;
    }
    .advisor-sec .nav-tabs .nav-item {
        font-size: 25px;
    }
    .track .form-control, .track .submit-btn {
        height: 56px;
        font-size: 20px;
    }
    .track-card {
        padding: 30px 20px;
    }
    .track-card .track-card-img {
        width: 75px;
    }
    .track-card p {
        margin-bottom: 0px !important;
    }
    .track-card h5 {
        font-size: 25px;
    }
    .companies-growth .cust-offset {
        padding-left: 6%;
    }
    .companies-growth .col-md-4:before {
        left: -21px;
        top: -69px;
        height: 234px;
    }
    .companies-growth .col-md-4:last-child:after {
        height: 234px;
        right: 85px;
    }
	/*.header {*/
	/*	margin-top: 42px;*/
	/*}*/
	.news-cust-advs .white-box .advisor-card h4 {
        font-size: 26px;
    }
    .news-cust-advs .white-box .advisor-card .cust-ad-para {
        height: 150px;
    }
	.right-nav img {
		width: 37px;
		height: 37px;
	}
	
	.cargo-consolidation .container, .other-services-sec .container {
		max-width: 1580px;
		padding-left: 5%;}
		
		
	.ecommerce-section .container, .ecommerce-offerings .container {
	    max-width: 1599px;
	    padding-left: 3.5%;
	}
	.offering-list li:after {
		height: 86px;
	}
.ecommerce-section .device-img {
	padding-left: 3%;
    margin-top: -84px;
}
.ecommerce-section h4 {
    font-size: 28px;
	    margin-bottom: 14px;
}

.ecommerce-section h4 {
    font-size: 28px;
    line-height: 1.7;
    margin-right: 10%;
}
.ecommerce-section p {
    font-size: 22px; 
	line-height: 1.7;
    margin-bottom: 10px;
}
 

.ecommerce-section {
    padding: 83px 0px 0px;
}

.digital-title {
    font-size: 32px;
}
.ecommerce-offerings { 
    padding: 57px 0px;
}
.ecommerce-section h2 {
    font-size: 50px;
}

.booking-card .booking-icon { 
    top: 30px;
    left: -34px;
    width: 85px;
}
.booking-card h4 {
    font-size: 29px;
	margin-bottom: 18px;
}
.digital-title { 
    margin-bottom: 60px;
}
.booking-card p {
    font-size: 18px;
    line-height: 1.7;
}
.booking-link .Check-btn { 
    font-size: 15px;
    padding: 16px 25px;
}
.booking-link .registere-link { 
    font-size: 18px; 
}
.booking-card {
    width: 83%;
    margin: 0px 0px 60px 50px;
	padding: 33px 15px 15px 70px;
}
.in-news-details table {
    font-size: 16px;
}
.ecommerce-section .breadcrumb-wrap{padding-bottom: 18px;}
		
	.container {
		max-width: 1599px;
	}
	.primary-header p {
		font-size: 17px;
	}
	.contact-header a {
		font-size: 18px;
	}
	.navbar-nav {
		padding-left: 20px;
	}
	.navbar-nav li {
		margin-right: 25px;
	}
	.navbar-light .navbar-nav .nav-link {
		font-size: 20px;
		font-weight: 500;
	}
	.navbar {
	    padding: 17px 76px;
	}
	.right-nav .dropdown > a {
		font-size: 20px;
		font-weight: 500;
		margin-left: 20px;
	}
	.right-nav img {
		margin-right: 10px;
	}
	
	/*.banner-sec {*/
	/*    padding: 130px 0 0;*/
	/*}*/
	.main-title {
		font-size: 62px;
	}
	.sub-menu a {
	    font-size: 20px;
	}
	.digital-txt {
	    font-size: 50px;
	    /*line-height: 1;*/
	    margin-bottom: 120px;
	}
	.banner-sec {
		min-height: 650px;
	}
	.banner-txt {
		font-size: 24px;
	}
	.banner-txt a {
		font-size: 21px;
		margin-left: 15px;
	}
	.banner-txt svg {
		width: 20px;
	}
	.sales-exe .exe-prof {
	    width: 543px;
	}
	.exe-prof strong {
		font-size: 24px;
	}
	.exe-prof .txt {
		font-size: 17px;
	}
	.exe-prof .info {
	    /*width: 277px;*/
	    width: 100%;
	}
	.exe-prof .info {
	    left: 42%;
	}
	.offering-list li:first-child .offer-icn {
	    width: 94px;
	    height: 99px;
	}
	.offering-list li .offer-icn {
	    margin-right: 10px;
	    /*width: 115px;*/
	    /*height: 115px;*/
	    width: 80px;
	    height: 80px;
	}
	.offering-list li:first-child {
		padding-left: 0;
	}
	
	/*.offering-list li {*/
	/*	padding-right: 20px;*/
	/*}*/
	.offering-list .offer-txt {
		font-size: 19px;
		line-height: 28px;
	}
	.companies-group .cust-pl:first-child {
        padding-left: 35px;
    }
    .companies-group .cust-pl:nth-child(2) {
        padding-left: 80px;
    }
	.companies-group {
	    padding: 28px 0;
	}
	.companies-group strong {
		/*width: 32%;*/
		width: 100%;
		font-size: 21px !important;
	}
	.companies-group .first-ul strong {
	        padding-right: 60px;
	}
	.companies-group strong.members-of {
        padding-right: 30px !important;
    }
	.companies-group img {
        width: 86%;
        margin-right: 65px;
    }
    .members-logo {
        width: 80% !important;
    }
    .companies-group:after {
        left: 47%;
    
    }
	.companies-group span {
		width: 69%;
	}
	.companies-growth {
	    padding: 50px 0px;
	}
	.companies-growth .container {
		/*max-width:1290px; */
		/*max-width: 1390px;*/
		max-width: 1670px;
	}
	.companies-growth .cust-offset .col-md-4 {
        padding-left: 0%;
        padding-right: 0%;
    }
	.companies-growth .cust-offset .col-md-4:nth-child(2) {
        padding-left: 0%;
        padding-right: 0%;
    }
	.companies-growth .growth-title {
		font-size: 32px;
	}
	.companies-growth .desc {
		font-size: 18px;
	}
	.our-services {
	    padding: 89px 0 63px;
	}
	.our-services .service-title {
		font-size: 45px;
	}
	.our-services .desc {
		font-size: 22px;
		margin-bottom: 37px;
	}
	.e-do .desc {
		font-size: 22px;
		/*margin-bottom: 37px;*/
	}
	.service-card .service-detail .service-icn {
		width: 90px;
	}
	.service-card .service-detail h4 {
		font-size: 25px;
	}
	.service-card .service-detail {
		padding: 25px 62px;
	}
	.service-card .service-detail p {
		font-size: 17px;
	}
	.advisor-sec {
	    padding: 64px 0 50px;
	}
	.advisor-sec .service-title {
		font-size: 45px;
		margin-bottom: 40px;
	}
	.advisor-sec .btn-primary {
		font-size: 20px;	
		font-weight: 500;
		padding: 17px 50px;
		margin-bottom: 46px;
	}
	.advisor-sec .btn-primary {
		border-radius: 40px;
	}
	.register-sec .submit-details-btn {
        border-radius: 40px;
    }
	.advisor-sec .container{
	    max-width: 1548px;
	}
	.advisor-card figure {
	    margin-bottom: 32px;
	}
	.advisor-card p {
		font-size: 19px;
	}
	.advisor-card .meta-data {
        font-size: 16px;
    }
	.advisor-card h4 {
		font-size: 24px;
		margin-bottom: 25px;
	}
	.cust-ad-para {
	        height: 212px;
	}
	.news-details.cust-ad-para {
        height: 310px;
    }
	.advisor-card a, .news-read-more a {
		font-size: 19px;
	}
	.primary-footer {
	    padding: 58px 0 30px;
	}
	.primary-footer .footer-brand {
		width: 170px;
	}
	.primary-footer p {
		font-size: 16px;
		margin-bottom: 44px;
	}
	.primary-footer h4 {
		font-size: 24px;
	}
	.location-list span {
		font-size: 20px;
	}
	.location-list svg {
	    width: 14px;
	    margin-right: 10px;
	}
	
	.footer-nav a {
		font-size: 20px;
	}
	.social-list li a {
		width: 44px;
		height: 44px;
	}
	
	
	
.cargo-consolidation h2 {
    font-size: 40px;
    margin-top: 20px;
}
.breadcrumb-wrap .breadcrumb {
    font-size: 20px;
    margin-bottom: 0;
}

.cargo-consolidation .btn-wrap span {
    width: 36px; 
}
.cargo-consolidation .btn-wrap .btn-primary {
    font-size: 20px;
    padding: 18px 17px;
}


.cargo-consolidation .btn-wrap .btn-primary.btn-book {
    padding: 18px 48px 18px 20px;
}
.cargo-consolidation .btn-wrap .btn-primary.btn-book span {
	width:46px;
}
	.cargo-consolidation {
    padding: 37px 0;
}

.other-services-sec .service-title {
    padding: 38px 0px  13px 33px;
}
.other-services-sec .service-title {
    font-size: 20px;
}
.service-title span {
    width: 37px;
}

.consolidation-details { 
    padding-left: 20px;
}

.service-list li a {
    /*margin-left: 8px;*/
    font-size: 20px;
    padding: 20px 21px;
}

/*.service-list li a:hover, .service-list li.active a {*/
/*    border-left: 4px solid #0A64A2;*/
/*    background: rgba(10,100,162,0.11);*/
/*}*/

.service-list li a:after { 
    right: 24px; 
    height: 28px;
    width: 25px; 
}
.other-services-sec .service-block { 
    margin-bottom: 50px;
}
	.consolidation-details figure {
    margin-bottom: 38px;
}
.consolidation-details h4 {
    font-size: 28px;
}
.consolidation-details h4 { 
    margin-bottom: 20px; 
}
.consolidation-details p {
    font-size: 22px;
	line-height: 1.7;
	margin-bottom: 45px;
}
.most-service{padding-left:20px}
.most-service img {
    width: 65px;
}
.most-service .list-item {
    padding-left: 90px;
}
.most-service .list-item strong {
    font-size: 22px;
    line-height: 36px;
}
.most-service .list-item p {
    font-size: 18px;
}
.other-services-sec { 
    margin-bottom: 40px;
}



.news-sec .container {    max-width: 1416px;}
.news-sec figure { 
    padding-right: 25px;
}

.news-sec p span, .recent-news-sec p span {
    font-size: 20px;
    padding-top: 12px;
    font-family: 'Gothic720 BT';
}
.news-sec h2, .recent-news-sec h2 {
    font-size: 36px;
}
.news-sec p, .recent-news-sec p {
    font-size: 22px;
}
.news-sec .more-link {
    font-size: 18px;
}
.recent-news-sec .container {    max-width: 1416px;}

.news-sec {
    margin: 50px 0px;
}
.recent-news-sec h6 { 
    margin-bottom: 22px;
}
.recent-news-sec .more-link {
    font-size: 20px;
}
.pagination-sec .page-link {
    border: 0;
    font-family: 'Gothic720 BT';
    font-size: 23px;
    color: #676767;
}

.pagination-sec .pagination-arrow { 
    height: 40px;
    width: 40px; 
}

.news-search .form-group .form-control {
    display: block;
    width: 100%;
    height: calc(3.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem; 
}
.news-search .form-group .form-control:first-child { 
    width: 300px; 
}
.news-search .form-group .form-control:last-child { 
    width: 200px; 
}
/*about page start*/
.cargo-consolidation.about-txt h2 {
	font-size: 50px;
}
.page-tabs-sec .nav-tabs .nav-link, .page-tabs-sec .nav-tabs .nav-link.active, .page-tabs-sec .nav-tabs .nav-item.show .nav-link {
    font-size: 19px;
}
.page-tabs-sec .nav-tabs .nav-item {
    padding: 0 30px;
}
.about-tab-content {
    padding-left: 5.8%;
}
.page-tabs-sec h4 {
    font-size: 30px;
    line-height: 1.5;
    margin-bottom: 25px;
}
.page-tabs-sec h4.post-title {
	margin-bottom: 5px;
}
.page-tabs-sec p {
    font-size: 22px;
    line-height: 1.73;
}
.page-tabs-sec ul li {
    font-size: 22px;
}
.accr-list li span {
    width: 119px;
    height: 119px;
}
.accr-list li strong {
	font-size: 19px;
}
.page-tabs-sec .about-banner {
    margin-bottom: 50px;
}
.page-tabs-sec p.desc {
	margin-bottom: 50px;
}
.page-tabs-sec p.desc {
    /*margin-bottom: 56px;*/
    margin-bottom: 20px;
}
.brochure-dwn {
    padding: 30px 25px;
    margin-bottom: 58px;
}
.brochure-dwn .dwn-desc {
    width: calc(100% - 515px);
    padding: 0 48px;
    font-size: 19px;
}
.brochure-dwn .dwn-btn {
	font-size: 20px;
    padding: 13px 25px;
}
.brochure-dwn .dwn-btn img {
    width: 42px;
}
.excellence-list {
    margin-bottom: 52px;
}
.excellence-list li strong {
	font-size: 30px;
}
.team-tab-content h2 {
    font-size: 36px;
    padding: 0 20%;
}
.team-tab-content {
    padding: 45px 0;
}
.team-tab-content .team-wrap {
    padding: 62px 9%;
}
.business-card figure {
	width: 170px;
}
.business-card {
    margin-bottom: 42px;
}
.business-card {
    padding: 35px 20px 0 20px;
}
.business-card strong {
    font-size: 29px;
}
.business-card span {
    font-size: 18px;
}
.business-card p {
    font-size: 20px;
}
.in-news-sec .container {
    padding-left: 5.5%;
}
.other-news h4 {
	font-size: 24px;
	padding: 0 0 26px;
	margin-bottom: 32px;
}
.other-news .view-all-news {
	font-size: 19px;
}
.other-news .view-img figure {
    width: 110px;
}
.other-news .post-title {
    width: calc(100% - 139px);
}
.in-news-sec span {
	font-size: 19px;
}
.in-news-sec .in-news-details {
	padding-left: 4%;
}
.in-news-sec h2 {
    font-size: 36px;
    margin-bottom: 22px;
}
.in-news-sec .in-title {
    font-size: 20px;
}
.other-news .post-title span {
	font-size: 17px;
}
.other-news .post-title h6 {
    font-size: 21px;
}
.other-news .view-img {
    margin-bottom: 30px;
    padding-bottom: 12px;
}
.in-news-sec .in-news-details figure {
	/*height: 477px;*/
	/*height: 384px;*/
    margin-bottom: 56px;
}
.in-news-sec .in-news-details figure img {
	width: 100%;
	height: 100%;
	object-fit:cover;
	border-radius: 22px;
	overflow: hidden;
}
.in-news-sec h3 {
    font-size: 30px;
}
.in-news-sec .desc {
    font-size: 22px;
    line-height: 1.7;
    margin-bottom: 47px;
}
.in-news-details h4 {
	font-size: 30px;
}
.in-news-sec .comment-box {
	padding-top: 33px;
}
.in-news-sec .comment-box  span {
    font-size: 18px;
    margin-bottom: 22px;
}
.in-news-sec .form-group {
    margin-bottom: 1.5rem;
}
.comment-box textarea.form-control {
    min-height: 203px;
    padding: 25px;
    font-size: 18px;
}
.comment-box input.form-control {
	font-size: 18px;
	height: 55px;
}
.comment-box .post-btn {
	font-size: 25px;
	padding: 20px;
	margin-bottom: 42px;
}
.in-news-details h4.recent-posts {
    margin-bottom: 85px;
}
.in-news-details .feedback-cmm h4 {
	font-size: 25px;
}
.feedback-cmm .message {
    padding: 29px 0px 84px 104px;
}
.feedback-cmm .profile-usser {
	width: 80px;
	height: 80px;
	font-size: 25px;
	font-weight: 500;
	margin-top: 3px;
}
.feedback-cmm .message h4 {
	margin-bottom: 20px;
}
.feedback-cmm .message h4 img {
	width: 30px;
}
.feedback-cmm .message h4 span {
	font-size: 20px;
}
.feedback-cmm .message p {
	font-size: 22px;
	line-height: 1.7;
    margin-bottom: 38px;
}
.message .share-ion img {
    width: 24px;
    margin-right: 13px;
}
.message .share-ion a {
	font-size: 17px;
}
.message .share-ion li {
    margin-right: 37px;
}
.in-news-details .more-comments {
	font-size: 19px;
}
.share-position li h2 {
	font-size: 25px;
}
.share-position li span {
	font-size: 13px;
}
.share-position img {
    width: 42px;
}
.login-dropdown {
	width: 248px;
}
.login-dropdown a {
	font-size: 18px;
	margin: 0;
}
.login-dropdown a svg {
    width: 10px;
}
.login-dropdown li {
padding: 18px 22px;
}
.customer-login {
    max-width: 1140px;
}
.customer-login .booking-card {
    width: 44%;
}
.customer-title {
    font-size: 40px;
}
.customer-login-banner {
	min-height: 560px;
	padding: 0;	
}
.tg-login-popup {
    width: 475px;
    padding: 23px 27px;
}
.tg-login-popup li {
    padding: 8px 0;
}
.tg-login-popup ul a {
    padding: 11px 20px 11px 11px;
}
.tg-login-popup h6 {
    font-size: 26px;
}
.tg-login-popup .nav-title {
	font-size:20px;
}
.tg-login-popup .nav-desc {
    font-size: 15px;
}
.tg-login-popup .nav-img {
	width: 56px;
	height: 56px;
}
.tg-login-popup .info {
    width: calc(100% - 70px);
}
.tg-login-popup .tg-top {
	padding-bottom: 10px;
}

.register-sec .back-arrow img {
    position: relative;
    transform: rotate(180deg);
    width: 9px;
    margin-right: 9px;
}

.register-sec .back-arrow {
    font-size: 18px;
    padding-top: 20px;
}
.register-sec .txt-pt{    padding: 4px 7px;
}
.register-sec h2 {
    font-size: 40px;
    text-align: left;
    margin-bottom: 40px;
}
.register-sec .register-details { 
    border-radius: 14px;
}
.register-sec .register-details {
    padding: 42px 38px 38px 40px;
}
.register-sec .register-details .form-control {
    font-size: 18px;
    height: calc(3.25rem + 2px);
    padding: 15px 15px;
}
.register-sec .register-details h6 {
    margin-bottom: 16px;
}
.register-sec  .container {max-width: 1432px;}

.register-details .form-row {
    margin-bottom: 21px;
}

.register-sec .register-details h6 {
    font-size: 19px;
}
.register-sec .check-services {
    padding: 35px 35px 30px 35px;
}
.register-sec .register-details .form-check-label {
    font-size: 19px;
    margin-left: 12px;
    margin-top: 8px;
}
.register-sec .register-details p {
    font-size: 17px;
    margin-left: 12px;
    line-height: 1.7;
}
.register-sec .check-services {
    margin-bottom: 35px;
}
.register-sec .submit-details-btn {
    padding: 11px 62px;
    font-size: 20px;
}

.register-sec .register-details input[type="radio"], input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
    width: 20px;
    height: 20px;
}
.register-sec .check-mb {
    margin-bottom: 40px;
}
.text-capitalize .global-icn img {
    width: 78px;
}

.global-services-sec {
    margin-top: 20px;
}
.global-services-sec .service-block span {
    width: 75px;
} 
.global-services-sec .service-block .service-title {
    width: calc(100% - 100px);
}
.global-services-sec .service-block a {
    font-size: 20px;
}

.global-services-sec .service-block .service-title span {
    width: 20px; 
}
 
 .global-services-sec .service-block .service-title h6 {
    font-size: 24px;
}


}



 /* body {
	background: url(../images/global.png)no-repeat;
	background-position: center top;
	background-size:100% auto;
}
.wrapper {
	opacity: 0.5;
} */  