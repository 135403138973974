.global-services-sec {
    margin: 40px 0 !important;
}
.other-locations {
    margin: 40px 0 !important;
}
.global-services-sec .service-block {
	cursor: pointer !important;
}
.global-services-sec .service-block.active > span img {
    -webkit-filter: brightness(0) invert() !important;
    filter: brightness(0) invert() !important;
}
.bullet-pt {
	position: relative;
	padding-left: 20px;
}
.bullet-pt:after {
	position: absolute;
	left: 0;
	top: 17px;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: #0a64a2;
	content:"";
}
@media all and (min-width: 1200px){
	.excellence-list li:first-child {
		border:0;
	}
	.page-tabs-sec .about-banner img {
		width: 100%;
	}
	.page-tabs-sec .nav-tabs .nav-link.active, .page-tabs-sec .nav-tabs .nav-item.show .nav-link {
		font-weight: 700;
	}
	.team-tab-content {
	    margin-bottom: 40px;
	}
	.milestones-wrapper {
		/*background: #f3f7f9;*/
		    background: #F6F7FB 0% 0% no-repeat padding-box;
		padding: 35px 3%;
		margin-top: 40px;
		/*border-radius: 25px;*/
		/*border: 1px solid rgb(201 201 201 / 30%);*/
		margin-bottom: 40px;
	}
	.milest-title {
		margin-bottom:40px;
	}
	.miles-point {
		padding: 40px 60px 20px 60px;
		background: #ffffff;
		border-radius: 15px;
		box-shadow: 0px 3px 6px #00000029;
		position: relative;
		margin-bottom: 30px;
	}
}
@media all and (min-width: 1200px) and (max-width: 1598px) {
	.cargo-consolidation.about-txt h2 {
		font-size: 36px;
		margin: 0;
	}
	.cargo-consolidation .btn-wrap .btn-primary {
	    font-size: 18px;
	    padding: 12px 27px;
	}
	.cargo-consolidation .btn-wrap span {
	    width: 22px;
	    margin-right: 15px;
	}
	.page-tabs-sec .nav-tabs .nav-link, .page-tabs-sec .nav-tabs .nav-link.active, .page-tabs-sec .nav-tabs .nav-item.show .nav-link {
	    font-size: 15px;
	    padding: 0.5rem 1.5rem;
	}
	.page-tabs-sec .nav-tabs .nav-link.active, .page-tabs-sec .nav-tabs .nav-item.show .nav-link {
		font-weight: 700;
	}
	.excellence-list li span {
		width: 50px;
		height: 50px;
	}
	.excellence-list li strong {
	    font-size: 17px !important;
   	}
	
}