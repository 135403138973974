/* .container{
 ::-webkit-scrollbar {
          width: 5px; 
        }
        
        ::-webkit-scrollbar-thumb {
          background-color: black; 
          border-radius: 4px; 
        }
} */

/* Sustainability.css */
/* .container scrollbar-container{ */
  /*  */
        
        #myTab::-webkit-scrollbar {
          width: 4px;
          height: 4px;
        }
        
        #myTab::-webkit-scrollbar-track {
         
          background-color: transparent;

        }
        
        /* #myTab::-webkit-scrollbar-thumb {
          
          background-color: transparent;

          border-radius: 2px;
        } */
        