html {
	scroll-behavior: smooth;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
	border-color: transparent;
}

header {
	position: fixed;
	top: 0;
	z-index: 9;
	width: 100%;
}

.content {
	padding-top: 6%;
}

a {
	text-decoration: none !important;
	font-size: 15px;
}

.comment-box,
.feedback-cmm {
	display: none;
}

#photos-btn {
	display: none;
}

.website-policy p {
	font-family: 'Gothic720 BT';
}

.website-policy ul {
	font-size: 15px;
	list-style: disc;
	line-height: 23px;
}

.e-do .desc {
	color: #9B9B9B;
	font-size: 16px;
	line-height: 1.5;
	font-family: 'Gothic720 BT';
}

.e-do-content {
	margin: 40px 0;
}

.e-do-content .e-do h4 {
	padding-top: 30px;
}

.thank-you {
	min-height: 400px;
	background: url(../images/thank-you-banner.jpg) no-repeat;
	-webkit-background-size: cover;
	background-size: cover;
	/*padding: 30px 0 0;*/
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
}

.thank-you .check-icon {
	width: 45px;
}

.thank-you h1 {
	font-size: 30px;
	text-align: center;
	font-weight: 600;
	line-height: 40px;
	margin-top: 15px;
	margin-bottom: 15px;
}

.thank-you p {
	color: #fff;
	font-size: 17px;
}

.thank-you a {
	font-size: 15px;
}

.news-sec figure img {
	border-radius: 22px;
	width: 100%;
	height: 300px;
}

.news-sec iframe,
.recent-news-sec iframe {
	border-radius: 22px;
}

.recent-news-sec .post-row img {
	border-radius: 22px;
	width: 100%;
	/*height: 300px;*/
}

/*Banner style*/
.banner-sec {
	min-height: 500px;
	background: url(../images/ban.jpg) no-repeat;
	-webkit-background-size: cover;
	background-size: cover;
	padding: 30px 0 0;
}

.main-title {
	font-size: 30px;
	color: #fff;
	line-height: 1.5;
	text-transform: capitalize;
	font-weight: 700;
}

.banner-sec>img {
	width: 100%;

}

/*track page style*/

.track {
	background-color: #0770B4;
	padding: 30px 0;
}

.track .form-control {
	height: 42px;
	padding: 10px 20px;
	font-size: 13px;
	border-radius: 8px;
}

.sailing-schedule-form .submit-btn {
	background-color: #004371;
	border-radius: 8px;
	padding: 9px 18px !important;
	color: #fff;
	border: none;
	cursor: pointer;
}

.sailing-schedule-result th {
	background-color: #0072bc;
	color: white;
}

#schedule_result_table th {
	border-top: 1px solid #ddd;
	border: 1px solid #dbdbdb;
}

.fa-arrow-down:before {
	content: "\f063";
}

#schedule_result_table td {
	border: 1px solid #dbdbdb;
}

.track .submit-btn {
	background-color: #004371;
	border-radius: 8px;
	padding: 9px 38px;
	margin-left: 4px;
}

.track .submit-btn:disabled {
	opacity: 0.5;
}

.track-card {
	/*border-radius: 20px;*/
	/*box-shadow: 0px 3px 27px #00000008;*/
	/*border: 1.5px solid #EFF5FF;*/
	padding: 18px 15px;
	/*border-right: 1px solid #ccc;*/
	position: relative;
}

.track-card:after {
	content: '';
	position: absolute;
	width: 1px;
	height: 90px;
	background-color: #e8e8e8;
	top: 15px;
	right: 0;
}

.track-card-after-none:after {
	content: none;
}

.track-card .track-card-img {
	width: 45px;
	margin-right: 20px;
}

.track-card h5 {
	font-weight: 700;
	color: #0E3855;
	font-size: 17px;
}

.track-card p {
	color: #0E3855;
	line-height: 1.4;
}

/*career page styling*/
.teamglobal-logistics {
	padding-top: 20px;
	padding-bottom: 40px;
}

.teamglobal-logistics .current-openings {
	background-color: #F6F7FB;
	padding: 40px;
}

.teamglobal-logistics .current-openings .box {
	background-color: #FFFFFF;
	border-radius: 20px;
	box-shadow: 0px 3px 27px #00000008;
	border: 1.5px solid #EFF5FF;
	padding: 20px;
}

.teamglobal-logistics .current-openings h4 {
	margin-bottom: 30px;
}

.table thead th {
	border-top: none;

}

.table thead th,
.table td {
	font-weight: 400;
	font-size: 15px;
	line-height: 22px;
}

.cust-width-td {
	width: 30%;
}

.table td a {
	color: #0072BE;
}

.table td a:hover {
	text-decoration: underline;
}

.exe-prof {
	position: relative;
	/*display: none;*/
}

.exe-prof .info {
	position: absolute;
	left: 50%;
	bottom: 25px;
	transform: translateX(-50%);
	width: 180px;
	color: #fff;
	text-align: center;
}

.tiva-whtsapp {
	padding-bottom: 10px;
	position: relative;
	font-size: 17px;
	font-weight: 500;
	margin-bottom: 10px;
}

.tiva-whtsapp:after {
	content: '';
	position: absolute;
	width: 43%;
	height: 1px;
	background-color: #fff;
	left: 101px;
	bottom: 0;
}

.exe-prof strong {
	color: #fff;
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 15px;
	text-decoration: underline;
}

.exe-prof .txt {
	color: #fff;
	font-size: 16px;
}

.digital-txt {
	font-family: 'DJB Get Digital';
	line-height: 1.4;
	color: #fff;
	font-size: 26px;
	margin-bottom: 20px;
}

.banner-txt {
	font-size: 16px;
	color: #fff;
	font-weight: 700;
}

.banner-txt a {
	color: #FFAC58;
	text-transform: capitalize;
	font-weight: 700;
	text-decoration: underline;
	font-size: 14px;
	padding-left: 10px;
}

.banner-txt svg {
	width: 16px;
	margin-left: 7px;
}

.offering-list li {
	display: flex;
	align-items: center;
	margin-bottom: 0px;
}

.offering-list li a {
	display: flex;
	align-items: center;
}

.offering-list li:first-child {
	padding-left: 10px;
}

.offering-list .offer-txt {
	font-weight: 700;
	color: #fff;
	font-size: initial;
	line-height: 22px;
	font-size: 15px;
}

.offering-list li:first-child .offer-icn {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	overflow: hidden;
}

.offering-list li .offer-icn {
	width: 65px;
	height: 65px;
	margin-right: 15px;
}

.sales-exe .exe-prof {
	width: 200px;
	margin-right: -15px;
}

.companies-group {
	padding: 30px 0;
	background: #F9F9F9 0% 0% no-repeat padding-box;
}

.companies-group .wrap .info:first-child {
	margin-bottom: 30px;
}

.companies-group strong {
	color: #333333;
	margin-bottom: 15px;
	text-transform: capitalize;
}

.companies-growth {
	/*background: transparent linear-gradient(180deg, #0072BE 100%, #00395F 270%) 0% 0% no-repeat padding-box;*/
	/*background-image: linear-gradient(#0072be, #00395f 85%);*/
	padding: 40px 0;
	background-image: linear-gradient(180deg, #0072BA 57%, #00395f 141%);
}

.companies-growth .cust-offset {
	/*padding-left: 11%;*/
	padding-left: 5%;
	padding-right: 0%;
	align-items: center;
	justify-content: center;
}

.companies-growth .growth-title {
	color: #FFFFFF;
	text-transform: capitalize;
	font-size: 22px;
	font-weight: 700;
	margin-bottom: 5px;
}

.companies-growth .desc {
	color: #FFFFFF;
	text-transform: capitalize;
	font-size: 13px;
	/*margin-bottom: 25px;*/
	margin-bottom: 0px;
}

.our-services {
	padding: 30px 0;
}

.our-services .service-title {
	color: #131D46;
	text-transform: capitalize;
	font-size: 33px;
	font-weight: 700;
}

.our-services .desc {
	color: #9B9B9B;
	/*font-weight: bold;*/
	font-size: 16px;
	line-height: 1.5;
	font-family: 'Gothic720 BT';
}

.our-services .desc a {
	color: #0072BE;
}

.service-card {
	position: relative;
	margin-bottom: 15px;
	overflow: hidden;
}

.service-card>img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.service-card .service-detail {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 20px;
	transition: all 0.3s ease-in-out;

}

.service-card:hover .service-detail {
	background: rgba(7, 112, 180, 0.8);
	top: 0;
}

.service-card .service-detail h4 {
	font-size: 16px;
	color: #fff;
	font-weight: 400;
	text-transform: capitalize;
	margin-top: 7px;
}

.service-card .service-detail p {
	line-height: 1.5;
	font-size: 14px;
	color: #fff;
	display: none;
}

.service-card .service-detail a {
	line-height: 1.5;
	font-size: 15px;
	color: #fff;
	display: none;
	text-decoration: underline;
}

.service-card:hover .service-detail p {
	display: block;
}

.service-card:hover .service-detail a {
	display: inline-block;
}

.service-card .service-detail .service-icn {
	width: 42px;
	margin-bottom: 10px;
}

.service-card .service-detail .service-icn img {
	width: 50px;
	height: 35px;
}

.advisor-sec .tab-content {
	padding-top: 30px;
	background-color: #ffff;
	padding-left: 50px;
	padding-right: 50px;
}

.advisor-sec .nav-tabs .nav-link.active,
.advisor-sec .nav-tabs .nav-item.show .nav-link {
	border: none;
}

.advisor-sec .nav-tabs {
	border-bottom: none;
	justify-content: center;
}

.view-all-cta {
	margin-top: -27px;
	border-radius: 32px;
}

.advisor-sec .nav-tabs .nav-item {
	flex: 0 0 auto;
	padding: 12px 30px;
	font-size: 20px;
}

.advisor-sec .nav-tabs .nav-link.active {
	font-weight: 600;
}

.advisor-sec .service-title {
	color: #131D46;
	text-transform: capitalize;
	font-size: 33px;
	font-weight: 700;
	margin-bottom: 30px;
}

.advisor-sec {
	padding: 30px 0;
	background: #F4F4FA 0% 0% no-repeat padding-box;
}

.advisor-sec .btn-primary {
	margin-bottom: 20px;
	border-radius: 27px;
}

.advisor-card {
	padding-bottom: 30px;
}

.advisor-card figure {
	margin-bottom: 15px;
}

.advisor-card figure img {
	border-radius: 10px;
}

.advisor-card p {
	line-height: 1.5;
	font-size: 14px;
	font-family: 'Gothic720 BT';
}

.advisor-card .meta-data {
	font-size: 14px;
}

.advisor-card h4 {
	font-size: 22px;
	color: #333333;
	font-weight: 700;
	/*text-transform: capitalize;*/
	margin-bottom: 25px;
	font-family: 'Poppins', sans-serif;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	min-height: 52px;
}

.advisor-card a,
.news-read-more a {
	color: #0770B4;
	font-size: 14px;
	font-weight: 500;
	/*text-decoration: underline;*/
	font-family: 'Gothic720 BT';
}

.news-cust-advs .white-box {
	background-color: #Fff;
	border: 1px solid #F2F2F4;
	padding: 30px 15px 0px 15px;
}

.news-cust-advs .white-box .advisor-card {
	padding-left: 10px;
	padding-right: 10px;
}

.news-cust-advs .white-box .advisor-card h4 {
	margin-bottom: 15px;
}

.news-cust-advs .white-box .col-md-12:first-child {
	border-bottom: 1px solid #f1f1f1;
	margin-bottom: 30px;
}

.news-cust-advs .white-box .advisor-card .read-more-text a {
	text-decoration: underline;
}

/*DK style*/

/*Services style*/

.breadcrumb-wrap .breadcrumb {
	background: #EDF6FC;
	padding: 0px;
	font-size: 12px;
	line-height: normal;
}

.breadcrumb-wrap .breadcrumb-item::after {
	padding-left: 5px;
}

.breadcrumb-wrap .breadcrumb-item a {
	color: #0072BE
}

.breadcrumb-wrap .breadcrumb-item.active {
	color: #333;
}

.breadcrumb-item+.breadcrumb-item::before {
	display: inline-block;
	padding-right: 5px;
	color: #000000;
	content: ">";
}

.breadcrumb-wrap .breadcrumb-item:last-child {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 412px;
}

.cargo-consolidation {
	background: #EDF6FC;
	padding: 15px 0;

}

.contact-head .cargo-consolidation {
	background-color: transparent;
}

.cargo-consolidation .btn-wrap .btn-primary {
	font-weight: 500;
	font-size: 13px;
	padding: 6px 12px;
	/*width:264px;*/
	width: auto;
	/*height:50px;*/
	height: auto;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 32px;
	margin-left: 10px;
}

.cargo-consolidation .btn-wrap .btn-primary.btn-book {
	background: #F5932F 0% 0% no-repeat padding-box;
}

.cargo-consolidation .btn-wrap .btn-primary:first-child {
	margin: 0;
}

.cargo-consolidation .btn-wrap .btn-primary:focus {
	color: #fff;
}

.cargo-consolidation .btn-wrap span {

	width: 14px;
	margin-right: 10px;
}

.cargo-consolidation h2 {
	margin-bottom: 0px;
	font-weight: 700
}

.other-services-sec {
	margin-top: 40px
}

.other-services-sec .service-nav {
	background: #F9F9F9 0% 0% no-repeat padding-box;
	border: 0.30000001192092896px solid #ececec;
	border-radius: 20px;
	padding: 10px;
	opacity: 1;
	margin-bottom: 15px;
}

.other-services-sec .service-title {
	padding: 5px 10px;
	letter-spacing: 0px;
	color: #292929;
	font-family: 'Circular Std Book';
	border-bottom: 1px solid #C9C9C9;
	padding-bottom: 13px;
	font-size: 13px;
	font-weight: 100;
	display: flex;
	align-items: center;
}

.other-services-sec .service-title span {
	width: 22px;
	margin-right: 12px;
	margin-top: -6px;
}

.other-services-sec .service-block {
	background: #fff;
	border-radius: 12px;
	margin-bottom: 30px
}

.service-list li a {
	padding: 8px 8px;
	color: #0072BE;
	display: block;
	font-size: 12px;
	/*text-transform: lowercase;*/
}

.service-list li a:first-letter {
	text-transform: uppercase;
}

.service-list li.active a {
	border-left:2px solid #0A64A2;
	background: rgba(10, 100, 162, 0.11);
}

.service-list li a:hover {
	/*background: rgba(10,100,162,0.11); */
	font-weight: 600;
}

.service-list li a {
	position: relative;
	font-weight: 500;
}

.service-list li a:after {
	position: absolute;
	right: 25px;
	top: 50%;
	transform: translateY(-50%);
	display: inline-block;
	height: 17px;
	width: 11px;
	content: "";
	background: url(../images/arow-right-dark.svg)no-repeat;
	background-size: 60% 60%;
	background-position: center center;
}

.service-list li a:hover:after,
.service-list li.active a:after {
	background: url(../images/arow-right.svg)no-repeat;
	background-size: 60% 60%;
	background-position: center center;
}

.consolidation-details {
	margin-bottom: 50px
}

.consolidation-details figure {
	margin-bottom: 25px
}

.consolidation-details h4 {
	font-size: 18px;
	color: #313131;
	margin-bottom: 15px;
	font-weight: 700;
}

.consolidation-details p {
	color: #333;
	font-size: 14px;
	font-family: 'Gothic720 BT';
	line-height: 1.5;
}

.cargo-consolidation .btn-wrap {
	margin-top: -15px;
}

.most-service img {
	width: 40px;
}

.most-service .list-item {
	position: relative;
	text-transform: capitalize;
	margin-bottom: 50px;
	padding-left: 50px;
}

.most-service .list-item span {
	position: absolute;
	left: 0;
	right: 0;
}

.most-service .list-item strong {
	color: #131D46;
	font-size: 16px;
	padding-bottom: 6px;
}

.most-service .list-item p {
	font-size: 12px;
}










/*Ecommerce style*/


.ecommerce-section {
	padding: 20px 0px 0px;
}

.ecommerce-section .breadcrumb-wrap .breadcrumb {
	background: none
}

.ecommerce-section h2 {
	font-size: 30px;
	color: #333;
	margin-bottom: 25px;
	font-weight: 700;

}

.ecommerce-section h4 {
	font-size: 18px;
	color: #0E3855;
	text-transform: capitalize;
	margin-bottom: 20px;
	font-weight: 700;

}

.ecommerce-section p {
	font-size: 14px;
	line-height: 1.5;
	font-family: 'Gothic720 BT';
}

.digital-title {
	color: #0E3855;
	font-weight: 400;
	font-size: 18px;
	margin-bottom: 35px;
}


.ecommerce-offerings {
	background: #FAFCFF;
	padding: 50px 0px;
}

.booking-card h4 {
	color: #0E3855;
	font-size: 16px;
	font-weight: 700;
	text-transform: capitalize;
}


.booking-card {
	position: relative;
	padding: 15px 15px 15px 28px;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 27px #00000008;
	border-left: 2px solid #EFF5FF;
	border-radius: 20px;
	margin: 0px 0px 20px 30px;
}

.booking-card .booking-icon {
	position: absolute;
	top: 15px;
	left: -20px;
	width: 40px;
}


.booking-card p {
	font-size: 14px;
	line-height: 1.5;
	color: #0E3855;
	font-family: 'Gothic720 BT';
}

.booking-link .Check-btn {
	border-radius: 32px;
	font-size: 14px;
	padding: 6px 16px;
}


.booking-link .registere-link {
	color: #0770B4;
	border-bottom: 1px solid #0770B4;
	font-size: 14px;
	margin-left: 12px;
}


.cust-ad-para {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
	margin-bottom: 20px;
	height: 217px;
}

.news-details.cust-ad-para {
	height: 240px;
}

.news-read-more {
	margin-bottom: 60px;
}

.in-news-details table {
	font-family: 'Gothic720 BT';
	font-size: 14px;
	line-height: 1.5;
	color: #545454;
}

.in-news-details table p:last-child {
	border-bottom: none;
	margin-bottom: 0;
}

.in-news-details table td,
.in-news-details table th {
	border: 1px solid #dbdbdb;
	padding: 7px;
	text-align: left;
}

.news-search .form-group {
	position: relative;
}

.news-search .search-btn {
	position: absolute;
	top: 50%;
	right: 8px;
	/*width: 18px;*/
	transform: translateY(-50%);
	border: none;
	background-color: transparent;
	width: 31px;
	cursor: pointer;
}

.news-sec {
	margin: 30px 0px;
}

.news-sec figure {
	margin-bottom: 15px;
}

.news-sec p span,
.recent-news-sec p span {
	font-size: 12px;
	text-transform: capitalize;
	line-height: 1.5;
}

.news-sec h2,
.recent-news-sec h2 {
	color: #333;
	font-weight: 700;
	line-height: 1.5;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;

}


.news-sec p,
.recent-news-sec p {
	font-size: 13px;
	/*text-transform: capitalize;*/
	line-height: 1.5;
}

.recent-news-sec {
	margin-top: 30px;
}

.news-sec .more-link {
	color: #0770B4;
	font-size: 12px;
	border-bottom: 1px solid #0770B4;
}

.news-sec p,
.recent-news-sec p {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	font-family: 'Gothic720 BT';
}

.news-sec p span {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.recent-news-sec h6 {
	color: #0E3855;
	margin-bottom: 15px;

}



.post-row {
	/*border-top: 1px solid #E9E9E9;*/
	padding: 20px 0px 0;
}

.recent-news-sec figure {
	margin-bottom: 15px;
}

.recent-news-sec .more-link {
	color: #0770B4;
	font-size: 12px;
	line-height: 1;
	border-bottom: 1px solid #0770B4;
}

/*.post-row:last-child {*/
/*	border-bottom: 1px solid #E9E9E9; }*/

.pagination-sec {
	margin-top: 15px;
}

.pagination-sec .page-link {
	border: 0;
	font-family: 'Gothic720 BT';
	font-size: 14px;
	color: #676767
}

.pagination-sec .page-item.disabled .page-link {
	color: #1379d3;
	pointer-events: none;
	cursor: auto;
	background-color: #fff;
	border-color: #dee2e6;
}

.pagination-sec .page-item:first-child .page-link {
	margin-left: 0;
	border-radius: 50%;
}

.pagination-sec .page-item:last-child .page-link {
	margin-left: 0;
	border-radius: 50%;
}


.pagination-sec .pagination-arrow {
	background: rgba(36, 36, 36, 0.3);
	padding: 0px;
	height: 30px;
	width: 30px;
	line-height: 0
}

.pagination-sec .page-item:first-child .pagination-arrow {
	position: relative;
	transform: rotate(180deg);
}


.pagination-sec .pagination-arrow:hover {
	background: rgba(36, 36, 36, 1);
}

.pagination-arrow img {
	width: 8px;
}


.pagination-sec .active {
	color: #0770B4
}

.news-details {
	margin-bottom: 60px;
}

.in-news-sec h3 {
	font-size: 18px;
	text-transform: capitalize;
	color: #333;
	font-weight: 700;
	line-height: 1.5;
}



.in-title {
	color: #5481A0;
	font-size: 12px;
	padding-bottom: 15px;
}

.in-news-sec {
	padding: 30px 0px;
	background: #FBFBFB;
}

.in-news-sec span {
	font-size: 12px;
	/*text-transform: capitalize;*/
	line-height: 1.5;
	font-family: 'Gothic720 BT';
}

.in-news-sec h2 {
	text-transform: capitalize;
	color: #333;
	font-weight: 700;
	line-height: 1.5;
}

.in-news-sec figure {
	margin-bottom: 20px;
	margin-top: 20px;
}

.in-news-sec figure img {
	border-radius: 20px;
}

.in-news-sec p {
	font-size: 13px;
	font-family: 'Gothic720 BT';
	line-height: 1.5;
}

.in-news-details p:last-child {
	border-bottom: 1px solid #E9E9E9;
	padding-bottom: 40px;
}

.other-news {
	margin-bottom: 20px;
}

.other-news .view-img figure img {
	border-radius: 10px;
	height: 100%;
}

.in-news-details h4 {
	color: #313131;
	margin: 15px 0;
	font-size: 16px;
	font-family: 'HelveticaNeue';
}

.in-news-sec .comment-box span {
	color: #333333;
	text-transform: capitalize;
	opacity: 0.57;
}

.comment-box span {
	color: #333333;
	font-size: 11px;
}


.comment-box form {
	margin: 20px 0;
}

.comment-box .post-btn {
	background: #F3F4F5 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #0000000F;
	opacity: 1;
}

.comment-box .form-control {
	font-size: 13px;
}

.comment-box .btn {
	width: 100%;
	text-transform: capitalize;
	color: #0E3855;
	font-weight: 500;
	border-radius: 0;
	font-size: 12px;
	padding: 10px;
}

.feedback-cmm h6 {
	color: #0E3855;
	margin-bottom: 15px;
}

.feedback-cmm .message {
	border-top: 1px solid #E9E9E9;
	padding: 20px 0px 30px 45px;
	position: relative;
}

.profile-usser {
	position: absolute;
	top: 0;
	left: 0;
	height: 30px;
	width: 30px;
	background: #F3F4F5;
	border-radius: 50%;
	overflow: hidden;
	margin-top: 15px;
	color: #0E3855;
	font-weight: 500;
}

.feedback-cmm h4 {
	color: #0E3855;
	font-size: 14px;
	font-weight: 500;
	margin: 0 0 8px;
}

.share-ion {
	color: #0E3855;
	font-size: 12px;
}

.message .share-ion li {
	margin-right: 20px;
}

.message .share-ion img {
	width: 15px;
	margin-right: 7px;
}

.message .share-ion a {
	color: #0E3855;
}

.message p {
	margin-bottom: 25px;
}

.message h4 img {
	width: 17px;
	margin-right: 12px;
}

.message h4 span {
	color: #ACACAC;
}

.message:last-child {
	border-bottom: 1px solid #E9E9E9;
	margin-bottom: 20px;
}

.more-comments {
	color: #0770B4;
	border-bottom: 1px solid #0770B4;
	font-size: 12px;
	font-family: 'Gothic720 BT';
}

.other-news h4 {
	color: #0E3855;
	font-size: 15px;
	padding: 0 0 12px;
	border-bottom: 1px solid #E9E9E9;
	margin-bottom: 0;
}

.other-news .view-all-news {
	color: #0770B4;
	font-size: 13px;
	float: right;
	border-bottom: 1px solid #0770B4;
	font-weight: 500;
}

.other-news .view-img figure {
	width: 70px;
}

.other-news .post-title span {
	font-family: 'Gothic720 BT';
	color: #333333;
	text-transform: capitalize;
}

.other-news .post-title {
	width: calc(100% - 85px);
}

.other-news .post-title h6 {
	font-weight: 700;
	color: #333333;
	font-size: 12px;
	line-height: 1.5;
	margin-bottom: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.view-img {
	border-bottom: 1px solid #E9E9E9;
	margin-bottom: 0px;
	align-items: center;
}

.share-position {
	position: fixed;
	left: 2%;
	top: 50%;
	z-index: 9
}

.share-position img {
	width: 30px;
}

.share-position li {
	margin: 10px 0
}

.share-position li h2 {
	margin: 0;
	text-align: center;
	font-size: 16px;
}

.share-position li span {
	font-size: 11px;
	color: #333333
}

/*about start*/
.cargo-consolidation.about-txt {
	margin-bottom: 20px;
}

.cargo-consolidation.about-txt h2 {
	font-size: 28px;
}

.page-tabs-sec .nav-tabs .nav-link,
.page-tabs-sec .nav-tabs .nav-link.active,
.page-tabs-sec .nav-tabs .nav-item.show .nav-link {
	color: #3C3C3C;
	font-size: 14px !important;
	font-weight: 500;
}

.page-tabs-sec .nav-tabs .nav-link.active,
.page-tabs-sec .nav-tabs .nav-item.show .nav-link {
	border: 0;
	color: #0A64A2;
	position: relative;
}

.page-tabs-sec .nav-tabs .nav-link.active:after,
.page-tabs-sec .nav-tabs .nav-item.show .nav-link:after {
	position: absolute;
	bottom: -10px;
	left: 0;
	content: "";
	width: 100%;
	height: 2px;
	background: #0A64A2;
}

.page-tabs-sec .nav-tabs {
	white-space: nowrap;
	display: block;
	overflow: auto;
	padding: 10px 0;
	border-top: 1px solid #dee2e6;
	margin-bottom: 25px;
}

.page-tabs-sec .nav-tabs .nav-item {
	display: inline-block;
	margin-right: 10px;

}

.page-tabs-sec .about-banner {
	margin-bottom: 25px;
}

.page-tabs-sec h6,
.page-tabs-sec h4 {
	/*font-family: 'HelveticaNeue';*/
	font-weight: bold;
	font-size: 20px;
	color: #313131;
	margin-bottom: 15px;
}

.brochure-dwn .dwn-desc {
	font-style: italic;
}

.page-tabs-sec p {
	font-size: 14px;
	line-height: 1.7;
	color: #333333;
	font-family: 'Gothic720 BT';
}

.excellence-list {
	list-style: none !important;
}

.page-tabs-sec ul {
	list-style: disc;
	margin-bottom: 30px;
	padding-left: 20px;
}

.page-tabs-sec ul li {
	font-size: 14px;
	line-height: 1.7;
	color: #333333;
	font-family: 'Gothic720 BT';
}

.page-tabs-sec h6 {
	font-size: 16px;
}

.accr-list {
	margin: 0 -10px;
	width: calc(100% + 20px);
	text-align: center;
}

.accr-list li {
	display: inline-block;
	margin-right: 30px;
}

.accr-list li span {
	width: 100px;
	height: 100px;
	padding: 10px;
}

.accr-list li img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: all 0.3s ease-in-out;
	position: relative;
}

.accr-list li span:hover img {
	transform: scale(1.1);
}

.accr-list li strong {
	display: block;
	font-size: 16px;
	text-align: center;
	font-weight: 500;
	color: #0A64A2;
	margin-bottom: 15px;
}

.brochure-dwn {
	padding: 20px 0;
	border-top: 2px solid #0A64A2;
	border-bottom: 2px solid #0A64A2;
	margin-bottom: 25px;
}

.desc text-capitalize .brochure-dwn .dwn-desc,
.brochure-dwn .dwn-title {
	color: #0E3855;
	font-style: italic;
	font-size: 14px;
	padding: 0 0px !important;
}

.brochure-dwn .dwn-title {
	font-family: 'Poppins', sans-serif;
	font-size: 20px;
}

.brochure-dwn .dwn-btn {
	font-weight: 700;
	border-radius: 32px;
}

.brochure-dwn .dwn-btn img {
	width: 30px;
	margin-right: 8px;
}

.excellence-list {
	margin-bottom: 15px;
}

.excellence-list li {
	padding: 10px 0 20px;
}

.excellence-list li span {
	margin-bottom: 15px;
}

.excellence-list li strong {
	font-size: 24px;
	color: #131D46;
	line-height: 1.5;
}

.team-tab-content h2,
.about-tab-content h2 {
	font-size: 22px;
	font-weight: 700;
	color: #333333;
	line-height: 1.5;
	margin-bottom: 25px;
}

.team-tab-content .team-wrap {
	padding: 25px 5%;
	background: #F6F7FB 0% 0% no-repeat padding-box;
}

.business-card {
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 27px #00000008;
	border: 2px solid #EFF5FF;
	border-radius: 20px;
	padding: 15px 15px 0 15px;
	margin-bottom: 20px;

}

.business-card figure {
	width: 120px;
	margin: 0 auto 20px;
}

.business-card strong {
	margin-bottom: 10px;
	color: #0E3855;
}

.business-card span {
	font-size: 14px;
	color: #0E3855;
	margin-bottom: 7px;
}

.business-card p {
	font-size: 14px;
	line-height: 1.5;
	font-family: 'Gothic720 BT';
	color: #0E3855;
}

.comment-box .post-btn {
	font-weight: 500;
}

.in-news-details h4.recent-posts {
	color: #313131;
}

.feedback-cmm .message p {
	color: #868686;
	font-family: 'Gothic720 BT';
}

.in-news-details .more-comments {
	font-family: 'Gothic720 BT';
}

.customer-login .booking-card {
	border-left: 4px solid #A7DCFF;
}

.customer-title {
	font-size: 24px;
	color: #fff;
	font-weight: 700;
	line-height: 1.5;
	margin-bottom: 20px;
}

/*****************  ***************/

.register-sec {
	padding: 30px 0;
}

.register-sec h2 {
	font-size: 16px;
	font-weight: 700;
	margin-bottom: 20px;
}



.register-sec .back-arrow img {
	position: relative;
	transform: rotate(180deg);
	width: 7px;
	margin-right: 5px;
}

.register-sec .back-arrow {
	color: #0072BE;
	font-size: 12px;
	margin-bottom: 10px;
}

.register-sec .register-details {
	background: #F5FBFF;
	padding: 20px;
	border: 1px solid #0A64A2;
	border-radius: 10px;
}

.register-sec .register-details h6 {
	color: #0E3855;
	font-size: 12px;
	margin-bottom: 20px;
	font-weight: 700;
}

.register-sec .register-details .form-check-label {
	margin-bottom: 6px;
	font-size: 13px;
	color: #0E3855;
	font-weight: 700;
}

.register-sec .register-details p {
	font-size: 13px;
	line-height: 1.5;
	color: #0E3855;
	font-family: 'Poppins', sans-serif;
}

.register-sec .check-services {
	background: #fff;
	border: 1px solid #0E385555;
	border-radius: 6px;
	padding: 20px;
	margin-bottom: 20px;
}

.register-sec .check-mb {
	margin-bottom: 20px;
}

.register-sec .register-details .form-control {
	font-size: 12px;
}


.register-sec .submit-details-btn {
	background: #005F9E;
	color: #FFFFFF;
	font-family: 'Poppins', sans-serif;
	font-weight: 700;
	padding: 8px 35px;
	font-size: 12px;
	border-radius: 27px;
}

.text-capitalize .global-icn img {
	width: 35px
}

.global-services-sec {
	margin-top: 10px;
}

.global-services-sec .service-title {
	font-family: 'Poppins', sans-serif;
	padding: 0;
	margin: 0;
}

.global-services-sec .service-nav {
	background: #F9F9F9 0% 0% no-repeat padding-box;
	border: 0.30000001192092896px solid #C9C9C9;
	border-radius: 20px;
	padding: 20px 20px;
	opacity: 1;
	margin-bottom: 15px;
}

.global-services-sec .service-nav .check-now {
	color: #0770B4;
	border-bottom: 1px solid #0770B4;
	font-size: 14px;
	margin-top: 9px;
	display: inline-block;
	margin-bottom: 0;
	font-weight: 500;
}

.global-services-sec .service-block {
	background: #fff;
	border-radius: 12px;
	margin-bottom: 15px;
	padding: 12px 15px;
	position: relative;
	align-items: center;
}

.global-services-sec .service-block .service-title span {
	width: 10px;
	height: auto;
	position: absolute;
	right: 22px;
	top: 50%;
	transform: translateY(-50%);
}

.active .cust-img {
	background-color: rgb(255 255 255 / 6%);
}

.cust-img {
	background-color: #eff6fa;
	padding: 15px;
	border-radius: 100%;
}

.global-services-sec .service-block span {
	width: 40px;
}

.global-services-sec .service-block .service-title {
	width: calc(100% - 55px)
}

.global-services-sec .service-block .service-title h6 {
	margin: 0
}

.global-services-sec .active {
	background: #0A64A2;
	color: #fff
}

.global-services-sec .service-block a {
	font-size: 12px;
	color: #0770B4;
	border-bottom: 1px solid #0770B4
}


.global-services-sec .service-details-bg {
	padding: 20px;
	border: 1px solid #7D97AC;
	border-radius: 15px;
}

/*team login*/
.team-loin-banner {
	background: #eeeeee url(../images/team-login-bg.png) no-repeat;
	background-position: center top;
	-webkit-background-size: 100% auto;
	background-size: 1600px auto;
	min-height: 100vh;
}

.team-login-wrap {
	max-width: 266px;
	margin: 0 auto;
	padding: 140px 0;
}

.team-loin-banner .team-logo {
	margin-bottom: 25px;
}

.team-loin-banner h6 {
	margin-bottom: 25px;
}

.team-loin-banner .country-code {
	width: 50px;
	margin-right: 3px;
}

.team-loin-banner .country-code .form-control {
	padding-left: 5px;
	padding-right: 5px;
	text-align: center;
}

.team-loin-banner .field-icn {
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.team-loin-banner .forget-pwd {
	padding: 15px 0 10px;
}

.team-loin-banner .forget-pwd a {
	font-size: 14px;
	color: #1e90ff;
}

.team-loin-banner .form-control {
	background: #f1f1f1;
	box-shadow: none !important;
	border: 0;
	border-radius: 0;
	outline: 0 !important;
	font-size: 14px;
}

.team-loin-banner .form-group {
	background: #fff;
	border-radius: 6px;
	padding: 5px 10px;
	position: relative;
}

.team-loin-banner .form-text {
	font-size: 10px;
}

.team-loin-banner .btn-primary {
	width: 174px;
	display: block;
	margin: 0 auto 15px;
	border-radius: 32px
}

.team-loin-banner .btn-primary.btn-reg {
	background: #f0ad4e;
}

.team-loin-banner .btn-primary.btn-reg:hover {
	background: #ffe0d5;
}

.tg-login-popup {
	width: 262px;
}

.tg-login-popup h6 {
	font-weight: 700;
	color: #131D46;
	font-size: 18px;
}

.tg-login-popup .close-icn {
	width: 30px;
	height: 30px;

	overflow: hidden;
	padding: 0;
	margin: 0;
}

.tg-login-popup .close-icn img {
	width: 100%;
	height: 100%;
}

.tg-login-popup .nav-img {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	overflow: hidden;
}

.tg-login-popup ul a {
	border: 1px solid rgba(46, 46, 46, 0.5);
	padding: 5px 20px 5px 5px;
	border-radius: 6px;
}

.tg-login-popup .nav-desc {
	font-size: 11px;
}

.tg-login-popup .nav-img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.tg-login-popup .info {
	width: calc(100% - 45px);
}

.contact-head {
	background: url(../images/contact-banner.jpg) no-repeat center center;
	-webkit-background-size: cover;
	background-size: cover;
}

/*.contact-head .cargo-consolidation {*/
/*	background:rgba(0, 114, 190,0.6);*/
/*}*/
.contact-head .breadcrumb {
	background: none;
}

.contact-head .cargo-consolidation.about-txt h2 {
	color: #fff;
}

.contact-head .breadcrumb-item+.breadcrumb-item::before,
.contact-head .breadcrumb-wrap .breadcrumb-item.active,
.contact-head .breadcrumb-wrap .breadcrumb-item,
.contact-head .breadcrumb-wrap .breadcrumb-item a {
	color: #fff;
}

.contact-info-wrp {}

/* Let's get this party started */
.country-sub-menu::-webkit-scrollbar {
	width: 7px;
	-webkit-border-radius: 10px;
	border-radius: 10px;
}

/* Track */
.country-sub-menu::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px #DDDDDD;
	margin-right: 10px;
}

/* Handle */
.country-sub-menu::-webkit-scrollbar-thumb {
	-webkit-border-radius: 10px;
	border-radius: 10px;
	background: #585858;
	-webkit-box-shadow: inset 0 0 6px #DDDDDD;
}

.country-sub-menu::-webkit-scrollbar-thumb:window-inactive {
	background: #585858;
}

.country-list>li>a {
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.5;
	color: #0E3855;
	border-bottom: 3px solid #fff;
}

.country-list>li>a:hover {
	color: #0E3855;
	font-weight: 600;
	padding-bottom: 10px;
	border-bottom: 3px solid #FF8100;
}

.country-list>li>a.active {
	color: #0E3855;
	font-weight: 600;
	padding-bottom: 10px;
	border-bottom: 3px solid #FF8100;
	padding-right: 15px;
}

.country-list>li>a {
	line-height: 41px;
}

.country-list>li:hover .country-sub-menu {
	display: block;
}

.country-list>li>a {
	line-height: 41px;
	padding-bottom: 10px;
	padding-right: 15px;
}

.country-list>li {
	margin-bottom: 20px;
	position: relative;
}

.country-list .flag-icn {
	width: 33px;
	height: 41px;
	border-radius: 50%;
	overflow: hidden;
	vertical-align: top;
	margin-right: 5px;
}

.country-sub-menu {
	display: none;
	width: 198px;
	max-height: 280px;
	overflow: auto;
	position: absolute;
	left: 28px;
	top: 100%;
	background: #fff;
	text-align: left;
	padding: 10px 0;
	/*border:1px solid #ccc;*/
	box-shadow: 0px 3px 6px #00000029;
	z-index: 1;
}

.country-sub-menu li {
	display: block;
	width: 100%;
	padding: 12px 15px;
	font-weight: 400;
	background: #fff;
	font-size: 14px;
	color: #3C3C3C;
}

.country-sub-menu li:hover {
	background: #FDF1E5;
}

.country-sub-menu li a {
	display: block;
}

/***** dk css ****/

.contact-info-wrp .corporate-office {
	border: 0.5px solid #eaeaea;
	border-radius: 20px;
	padding: 40px 50px;
	margin-bottom: 15px;
}

.contact-info-wrp .corporate-office .corporate-title {
	margin-bottom: 35px;
}

.contact-info-wrp .corporate-office .corporate-title h4 {
	font-size: 15px;
	font-weight: 700;
}

.contact-info-wrp .corporate-office .btn-primary {
	background: #FF8100 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 20px;
	padding: 9px 55px;
	font-size: 14px;
	font-weight: 700;
}

.contact-info-wrp .corporate-office .btn-primary:focus {
	color: #fff;
}

.contact-info-wrp .corporate-office .address {
	position: relative;
	padding: 15px 15px 1px 38px;
	margin: 0px 0px 20px 0px;
	border-top: 1px solid #707070;
	font-family: 'Gothic720 BT';

}

.contact-info-wrp .corporate-office .address p {
	font-size: 12px;
	color: #385B72;
	line-height: 1.4;
}

.contact-info-wrp .corporate-office .address figure {
	width: 30px;
	position: absolute;
	top: 15px;
	left: 0px;
}

.cust-fig {
	top: 19px !important;
}

.contact-info-wrp .corporate-office .corporate-teamglobal {
	margin-bottom: 15px;
}

.contact-info-wrp .corporate-office .corporate-team h4 {
	color: #0E3855;
	font-size: 16px;
	font-weight: 400;
	position: relative;
	margin: 30px 0px 25px;
}

.contact-info-wrp .corporate-office .corporate-team h4:after {
	position: absolute;
	left: 0;
	bottom: 7px;
	content: "";
	width: 100%;
	height: 1px;
	background: #eaeaea;
}

.contact-info-wrp .corporate-office .corporate-team h4 span {
	background: #fff;
	padding-right: 10px;
	position: relative;
	z-index: 1;
}


.contact-info-wrp .corporate-office .corporate-team .teamglobal-card {
	background: #F0F5F8;
	border-radius: 14px;
	padding: 15px 11px;
	margin: 0 0 15px;
}

.contact-info-wrp .corporate-office .corporate-team .teamglobal-card p {
	color: #648093;
	font-size: 13px;
	margin: 0 0 5px;

}

.contact-info-wrp .corporate-office .corporate-team .teamglobal-card h2 {
	color: #0E3855;
	font-size: 16px;
	font-weight: 700;
	margin-bottom: 15px;

}

.contact-info-wrp .corporate-office .corporate-team .teamglobal-card a {
	color: #0E3855;
	font-size: 12px;
	/*border-top:1px solid #eaeaea;*/
	width: 100%;
	padding: 6px 0px;
}

.contact-info-wrp .corporate-office .corporate-team .teamglobal-card span {
	width: 13px;
}

.contact-info-wrp .contact-details {
	background: #F8F5F2 0% 0% no-repeat padding-box;
	border: 1px solid #FF8100;
	border-radius: 20px;
	padding: 20px;
	margin-bottom: 15px;
}

.contact-info-wrp .contact-details h3 {
	color: #0E3855;
	font-size: 18px;
	font-weight: 700;
	margin: 0 0 20px;
}

#fsub .form-control {
	border-radius: 27px;
}

.contact-info-wrp .contact-details .btn-primary {
	color: #fff;
	font-size: 14px;
	padding: 8px 30px;
	font-weight: 700;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 27px;
	background: #FF8100;
}

.dropdown-item:hover {
	color: #0072be;
	background-color: white;
}

.dropdown-item.active,
.dropdown-item:active {
	background-color: white;
}

.dropdown-item:focus {
	background-color: white;
}

.bd-placeholder-img {
	font-size: 1.125rem;
	text-anchor: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

@media (min-width: 768px) {
	.bd-placeholder-img-lg {
		font-size: 3.5rem;
	}
}

.b-example-divider {
	height: 3rem;
	background-color: rgba(0, 0, 0, .1);
	border: solid rgba(0, 0, 0, .15);
	border-width: 1px 0;
	box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
	flex-shrink: 0;
	width: 1.5rem;
	height: 100vh;
}

.bi {
	vertical-align: -.125em;
	fill: currentColor;
}

.nav-scroller {
	position: relative;
	z-index: 2;
	height: 2.75rem;
	overflow-y: hidden;
}

.nav-scroller .nav {
	display: flex;
	flex-wrap: nowrap;
	padding-bottom: 1rem;
	margin-top: -1px;
	overflow-x: auto;
	text-align: center;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
}