:root {
    --bs-primary: #0a64a2 !important;
    --bs-btn-bg: #0a64a2 !important;
    --bs-link-color: #0a64a2 !important;
}

label {
    font-size: 13px !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.cnttr input {
    text-align: center;
}

.cnttr label {
    width: 118%;
    text-align: center;
}

.flex-1 {
    flex: 1 !important;
}

.bggs .nav-tabs .p-4 {
    padding: 10px !important;
    margin-top: 10px;
    border-radius: 30px !important;
}

.nav-link {
    color: #333;
}

.tg-measure {
    padding: 0px 7px;
}

.tg-measure .card-body {
    padding: 9px 5px;

}

.btn-check:checked+.btn-outline-primary {
    border-radius: 30px 0 0 30px;
}

.form-floating>.form-control-plaintext~label::after,
.form-floating>.form-control:focus~label::after,
.form-floating>.form-control:not(:placeholder-shown)~label::after,
.form-floating>.form-select~label::after {
    height: auto;
}

.btn-primary:hover {
    background-color: #174665 !important;
    border-color: #174665;
    color: #fff;
}

.btn-light {
    font-size: 16px;
    padding: 7px 30px;
    border-radius: 30px;
}

.bggs .btn-primary {
    background-color: #FF8100;
    border-color: #FF8100;
    border-radius: 30px;
    font-size: 16px;
    padding: 7px 30px;
}

#weight-addon {
    border-radius: 0 30px 30px 0;
}

.btn {
    font-size: 16px;
    padding: 7px 30px;
    border-radius: 30px;
}

#weight-addon {
    margin-left: 0px;
}

.btn-primary {
    background-color: #FF8100;
    border-color: #FF8100;

}

.form-floating>.form-control {
    border-radius: 30px;
    height: calc(2.7rem + 5px);
    min-height: auto;
}

.form-floating>.form-control:not(:placeholder-shown) {
    padding-top: 1.3rem;
}

.rounded {
    border-radius: 30px !important;
}

#form_sec .bggs {
    background: #F8F5F2 0% 0% no-repeat padding-box;
    border: 1px solid #FF8100;
    border-radius: 20px;
    margin-bottom: 40px;
}

.form-floating>.form-select {
    border-radius: 30px;
    border: 1px;
}

.modal-footer .btn-primary {
    background-color: #FF8100 !important;
    border-color: #FF8100;

}

.btn-primary:hover {
    background-color: #174665;
    border-color: #174665;
    color: #fff;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
    background-color: #0a64a2;
    border-color: #0a64a2;
}

.btn-outline-primary {
    background-color: #fff;
    border-color: #0a64a2;
    color: #0a64a2;
}

.btn-primary:disabled {
    color: #fff;
    background-color: #0a64a2;
    border-color: #0a64a2;
}

.btn-outline-primary:hover {
    background-color: #fff;
    border-color: #0a64a2;
    color: #0a64a2;
}

.btn-outline-primary:active {
    background-color: #fff;
    border-color: #0a64a2;
    color: #0a64a2;
}

.btn-check:checked+.btn-outline-primary {
    background-color: #0a64a2;
    border-color: #0a64a2;
    color: #fff;
}

.btn-check:active+.btn-outline-primary {
    background-color: #fff;
    border-color: #0a64a2;
    color: #0a64a2;
}

body {
    font-family: 'Poppins', sans-serif;
    background-color: #fff;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    /* background-color: #0a64a2; */
    color: #000000;
}

.form-control {
    border-radius: 5px;
    border: none;
}

.form-control:focus {
    border: none;
    box-shadow: none;
}


.card {
    border-radius: 30px;
    border: solid 1px #d1d1d1;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.10);*/

}

.card-body {
    padding: 0;
}

.small {
    font-size: 12px;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.switch {
    width: 50px;
    height: 30px;
    position: relative;
    display: inline-block;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    cursor: pointer;
    background-color: gray;
    border-radius: 30px;

}

.slider:before {

    position: absolute;
    background-color: #fff;
    height: 20px;
    width: 20px;
    content: "";
    left: 5px;
    bottom: 5px;
    border-radius: 50%;
    transition: ease-in-out .5s;
}

.slider:after {
    content: "Off";

    color: white;
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 70%;
    transition: all .5s;
    font-size: 10px;
    font-family: Verdana, sans-serif;
}

input:checked+.slider:after {
    transition: all .5s;
    left: 30%;
    content: "On"

}

input:checked+.slider {
    background-color: blue;

}

input:checked+.slider:before {
    transform: translateX(20px);
}


/*.tg-measure {
    padding: 10px;
}*/


.nav-tabs .nav-link.active,
.nav-tabs .nav-link.active:hover {
    border-color: #0a64a2;
    color: #000000 !important;
}

.nav-tabs .nav-item.show .nav-link {
    background-color: #fff;

    color: #0a64a2;
}

.nav-link:hover {
    color: #0a64a2 !important;
}

span.required {
    position: absolute;
    background: #FF3737;
    width: 4px;
    height: 4px;
    left: 4px;
    top: 4px;
    z-index: 1;
    border-radius: 100%;
    display: none;
}

.w-20 {
    width: 23%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.container-data-row input,
.container-data-row input:hover,
.container-data-row input:active,
.container-data-row input:focus {
    background-color: transparent;
}

.invalid-feedback {
    font-size: 12px;
    padding-left: 15px;
}

#refNo {
    color: #0a64a2;
}

a.captcha_refresh {
    margin: 0 10px;
    color: #5c636a;
}

@media (max-width: 767px) {

    #form_sec .bggs {
        margin: 30px 5%;
        width: 90%;
    }

    .bggs .nav-tabs .p-4 {
        text-align: center;
    }

}